<template>
    <tr>
        <td class="CheckoutTableRemove">
            <a @click="this.eliminaRiga(Riga.CodiceArticolo, Riga.CodiceVariante)">
                <img src="images/DeleteIcon.png" srcset="images/DeleteIconRetina.png 2x" alt="Rimuovi" />
            </a>
        </td>
        <td class="CheckoutTableItem">
            <small>Articolo</small>
            <div class="CheckoutTableItemThumb">
                <div class="CheckoutTableItemThumbImageBox">
                    <div class="CheckoutTableItemThumbImage">
                        <router-link :to="{ name: 'DettaglioProdotto', params: {ProductId: this.Riga.CodiceArticolo ?? 'none' , CodiceSocieta: this.Riga.CodiceSocieta ?? 'none',} }" >
                            <product-image :PathArticolo="Riga.UrlImmagine" :Tipo="'_THUMB'"></product-image>
                        </router-link>
                    </div>
                </div>
                <div class="CheckoutTableItemThumbName">
                    <h3>{{ Riga.DescrizioneArticolo }}</h3>
                    <h4>{{ Riga.CodiceArticolo }}</h4>
                    <h5><i>Taglia</i><span>{{Riga.CodiceVariante}}</span></h5>
                </div>
            </div>
        </td>
        <td class="CheckoutTableQty">
            <small>Quantità</small>
            <div class="QtySelectorBox">
                <div class="QtySelectorReset"><a href="#"><img src="images/ResetIcon.png" srcset="images/ResetIconRetina.png 2x" alt=""></a></div>
                <div class="QtySelector">
                    <div class="QtySelectorButton" @click="this.lessQty()">-</div>
                    <input type="text" class="CurrentQty" v-model="this.quantitaRiga">
                    <div class="QtySelectorButton" @click="this.plusQty()">+</div>
                </div>
                <div class="QtySelectorRefresh"><a href="javascript:void(0)" @click="this.updateRigaCarrello()"><img src="images/RefreshIcon.png" srcset="images/RefreshIconRetina.png 2x" alt=""></a></div>
            </div>
        </td>
        <td class="CheckoutTablePrice"><small>Prezzo unit.</small><span>{{ toEUR(Riga.PrezzoListino) }}</span></td>
        <td class="CheckoutTableTotal"><small>Totale</small><span>{{ toEUR(Riga.PrezzoListino * Riga.Quantita) }}</span></td>
    </tr>
</template>
<script>
import {priceMixin, valutaMixin, risorseMixin} from "@/mixin.js";
import ProductImage from "@/components/ProductImage.vue";
import Loader from "@/components/Loader.vue";

export default {
    name: "CartSummaryRow",
    components: {  ProductImage, Loader },
    data(){
        return {
            quantitaRiga: 0           
        }
    },
    props: {
        Riga: Object
    },    
    watch:{
        quantitaRiga: function(newValue,oldValue){
            if(!isNaN(newValue)){
                if(newValue != ""){
                    if(newValue == 0){
                        this.eliminaRiga();
                    }else{
                        if(oldValue != 0){
                            this.updateRigaCarrello();
                        }
                    }   
                }
            }else{
                this.quantitaRiga = oldValue;
            }
        }
    },
    mixins: [priceMixin, valutaMixin, risorseMixin],
    methods: {    
        plusQty: function () {
            this.quantitaRiga++;
        },
        lessQty: function () {
            if( this.quantitaRiga > 0 ){
                this.quantitaRiga--;
            }
            if(this.quantitaRiga == 0){
                this.eliminaRiga();
            }
        },
        eliminaRiga : function(){
            this.$store.commit('setLoader', true);
            this.$store.dispatch({
                type: 'deleteCartRow',
                token: this.$store.getters.getToken,
                shop: this.$store.getters.getShop,
                idCarrello: this.$store.getters.getIdCarrello,
                idProdotto: this.Riga.CodiceArticolo,
                idVariante: this.Riga.CodiceArticoloVariante
            })
            .then(
                (data) => {
                    this.$store.commit('setLoader', false);
                }
            );
        },        
        updateRigaCarrello(){
            this.$store.commit('setLoader', true);
            this.$store.dispatch({
                type: 'updateCartRow',
                token: this.$store.getters.getToken,
                shop: this.$store.getters.getShop,
                idCarrello: this.$store.getters.getIdCarrello,
                idProdotto: this.Riga.CodiceArticolo,
                idVariante: this.Riga.CodiceArticoloVariante,
                quantita: this.quantitaRiga
            })
            .then(
                (data) => {                    
                    this.$store.commit('setLoader', false);               
                }
            );
        }
    },
    mounted: function(){     
        this.quantitaRiga = this.Riga.Quantita;
    }
}
</script>