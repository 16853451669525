<template>
    <h3 class="ShoppingInfoIndex">Indirizzi di destinazione</h3>
    <div class="ShoppingAddress">
        <div class="FormRow">
            <div class="FormBoxWide">
                <div class="FormRadioBox">
                    <div class="RadioField">
                        <input type="radio" name="TipoSpedizione" v-model="TipoSpedizione" id="casa" value="1"  @change="this.selectedIndir(10)" />
                        <span class="Radiomark">
                        </span></div>                                                    
                    <div class="RadioLabel">Spedizione (+10€)</div>
                    <div style="margin-top:10px;" v-html="this.IndirizzoSpedizioneCompleto"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="ShoppingAddress">
        <div class="FormRow">
            <div class="FormBoxWide">
                <div class="FormRadioBox">
                    <div class="RadioField">                                                 
                        <input type="radio" name="TipoSpedizione" v-model="TipoSpedizione" id="magazzino" value="-2" checked @change="this.selectedIndir(0)" />
                        <span class="Radiomark">
                        </span></div>
                    <div class="RadioLabel">Ritiro presso ns. magazzino</div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="this.showAggiornaDati" class="ShoppingAddNew">
        <div class="Button">
            <a href="javascript:void(0);" @click="openModalShipping()">Inserisci indirizzo</a>
        </div>
    </div>
    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content" name="shipping">
        <div class="Box">
            <form>
                <fieldset>
                    <legend>Indirizzo di destinazione</legend>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Nome</label>
                            <input type="text" v-model="Spedizione_Nome" maxlength="20">
                        </div>
                        <div class="FormBox50">
                            <label>Cognome</label>
                            <input type="text" v-model="Spedizione_Cognome" maxlength="40">
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBoxWide">
                            <label>Indirizzo (Via)</label>
                            <input type="text" v-model="Spedizione_Indirizzo" maxlength="40" >
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Città</label>
                            <input type="text" v-model="Spedizione_Citta" maxlength="40" >
                        </div>
                        <div class="FormBox50">
                            <label>CAP</label>
                            <input type="text" v-model="Spedizione_CAP" maxlength="10">
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Provincia</label>
                                <select name="provincia" v-model="Spedizione_Provincia">
                                    <option v-for="provincia in this.listaProvince" :key="provincia.CodiceProvincia" :value="provincia.CodiceProvincia">{{provincia.Descrizione}}</option>
                                </select>
                        </div>
                    </div>
                    <div class="WhiteSpace">&nbsp;</div>
                    <div class="FormRow">
                        <div class="FormBoxWide TwinButtons">
                            <div class="Button Small">
                                <input type="button" @click="resetDatiSpedizione();" value="Chiudi">
                            </div>
                            <div class="Button Small">
                                <input type="button" @click="this.SalvaIndirSped()" value="Salva" kstyle="margin-left:10px;">
                            </div>
                        </div>
                    </div>
                </fieldset>
                </form>
        </div>
    </vue-final-modal>

</template>
<script>
import AuthService from "@/services/auth.service.js";
export default {
    name: "BillingAddressCartB2C",
    data(){
        return {
            showModal:false,
            showAggiornaDati: false,
            TipoSpedizione: -2,
            
            Spedizione_Nome:"",
            Spedizione_Cognome:"",
            Spedizione_Indirizzo:"",
            Spedizione_Citta:"",
            Spedizione_CAP:"",
            Spedizione_Provincia:"",
        }
    },
    emits:['selected','disableCheckout'],
    watch:{
        TipoSpedizione: function(newValue){
            if(newValue == 1){
                this.showAggiornaDati = true;
            }else{
                this.showAggiornaDati = false;
            }
        },
        Spedizione_Nome: function(newValue){
            if(newValue == null || newValue.length == 0){
                this.$emit('disableCheckout',{Campo: 'Nome',Value: false});
            }else{
                this.$emit('disableCheckout',{Campo: 'Nome',Value: true});
            }
        },
        Spedizione_Cognome: function(newValue){
            if(newValue == null || newValue.length == 0){
                this.$emit('disableCheckout',{Campo: 'Cognome',Value: false});
            }else{
                this.$emit('disableCheckout',{Campo: 'Cognome',Value: true});
            }
        },
        Spedizione_Indirizzo: function(newValue){
            if(newValue == null || newValue.length == 0){
                this.$emit('disableCheckout',{Campo: 'Indirizzo', Value: false});
            }else{
                this.$emit('disableCheckout',{Campo: 'Indirizzo', Value: true});
            }
        }
    },
    computed: {
        IndirizzoSpedizioneCompleto: function(){     
            let result = "";
            if(this.Spedizione_Nome && !this.Spedizione_Cognome){
                result += "<b>" + this.Spedizione_Nome + "</b><br/>"
            }
            if(!this.Spedizione_Nome && this.Spedizione_Cognome){
                result += "<b>" + this.Spedizione_Cognome + "</b><br/>"
            }
            if(this.Spedizione_Nome && this.Spedizione_Cognome){
                result += "<b>" + this.Spedizione_Nome + " " + this.Spedizione_Cognome + "</b><br/>"
            }            
            if(this.Spedizione_Indirizzo){
                result += this.Spedizione_Indirizzo+" ";
            }
            if(this.Spedizione_CAP){
                result += this.Spedizione_CAP;
            }
            if(this.Spedizione_Citta){
                result += ", " + this.Spedizione_Citta;
            }
            if(this.Spedizione_Provincia){
                result += " (" + this.Spedizione_Provincia + ")";    
            }
            return result;
        },
        listaProvince: function(){
            let p = this.$store.getters.getProvince;
            if(p){
                return p;
            }else{
                return [] ;
            }
        },
    },
    methods:{
        selectedIndir: function(value){
            if(value == 0){
                this.$emit('selected',{indirizzo: 1,Spedizione: value});
            }else{
                this.$emit('selected',{indirizzo: this.$store.getters.getDatiSpedizione,Spedizione: value});
            }
        },
        openModalShipping() {
            this.showModal = true;
        },
        SalvaIndirSped: function(){
            let Spedizione = [];
            if (((!this.Spedizione_Nome.length > 0 || !this.Spedizione_Cognome.length > 0 || !this.Spedizione_Indirizzo.length > 0))) {
                this.$toast.error('Dati Spedizione non completi');
                this.IndirizzoSpedizioneInserito = Spedizione;
                return -1;
            }else{
                Spedizione["SPED_RAGSOC"] = this.Spedizione_Nome + " " + this.Spedizione_Cognome;
                Spedizione["SPED_INDIR"] = this.Spedizione_Indirizzo;
                Spedizione["SPED_CIVICO"] = this.Spedizione_Civico;
                Spedizione["SPED_CAP"] = this.Spedizione_CAP;
                Spedizione["SPED_CITTA"] = this.Spedizione_Citta;
                Spedizione["SPED_CODNAZI"] = "IT";
                Spedizione["SPED_CODPROV"] = this.Spedizione_Provincia;
                Spedizione["SPED_NOME"] = this.Spedizione_Nome;
                Spedizione["SPED_COGNOME"] = this.Spedizione_Cognome;
                this.$store.dispatch('setDatiSpedizione', JSON.stringify(Object.assign({}, Spedizione)));
                this.IndirizzoSpedizioneInserito = Spedizione;
                this.$emit('selected',{indirizzo: this.$store.getters.getDatiSpedizione,Spedizione: 10, From: 'b2c'});
                this.showModal = false;
                return 0;
            }
        },
        resetDatiSpedizione: function(){
            this.showModal = false;
        },
        SetindirSped: function(JIndir){
            if(JIndir != null && JIndir != ''){
                let Spedizione = JSON.parse(JIndir);
                this.Spedizione_Nome = Spedizione["SPED_NOME"];
                this.Spedizione_Cognome = Spedizione["SPED_COGNOME"];
                this.Spedizione_Provincia = Spedizione["SPED_CODPROV"];
                this.Spedizione_Citta = Spedizione["SPED_CODNAZI"];
                this.Spedizione_CAP = Spedizione["SPED_CAP"];
                this.Spedizione_Civico = Spedizione["SPED_CIVICO"];
                this.Spedizione_Indirizzo = Spedizione["SPED_INDIR"]; 
            }
        }
    },
    mounted:function(){
        let v = this.$store.getters.getDatiSpedizione;
        if(v != null){
            this.SetindirSped(v);
        }
        AuthService.getIndirizziSpedizione( this.$store.getters.getToken ).then(data => {
            if (!data) { 
                this.$router.push("/");
            } 
            else {                   
                this.listaIndirizzi = data; 
            }
        });
        
    }
}
</script>
<style>
.modal-container {
    align-items: center;
}
.modal-content {
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    width: 80%;
}
</style>