<template>
    <h3 class="ShoppingInfoIndex PopperShippingInfo"><span>Indirizzi di destinazione</span>
        <legend>
            <Popper>  
                <a class="PopUp">i</a>                        
                <template #content>
                    <p>Se si desidera spedire a un indirizzo differente da quelli indicati, aggiungere l'indicazione nel campo Note.</p>
                </template>
            </Popper>
        </legend>
    </h3>
    <div class="ShoppingAddress" v-for="indirizzo in this.listaIndirizzi" :key="indirizzo.CodiceIndirizzo">
        <div class="FormRow">
            <div class="FormBoxWide">
                <div class="FormRadioBox">
                    <div class="RadioField">
                        <input type="radio" name="TipoSpedizione" id="casa" v-model="this.k2Indir" :value="indirizzo.CodiceIndirizzo" @change="this.selectedIndir()" />
                        <span class="Radiomark"></span>
                    </div>                                                    
                    <div class="RadioLabel">{{this.FormatIndir(indirizzo)}}</div>                                                   
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AuthService from "@/services/auth.service.js";
export default {
    name: "BillingAddressCartB2B",
    data(){
        return {
            k2Indir: 0,
            listaIndirizzi: []
        }
    },
    computed:{
        Shop: function(){
            return this.$store.getters.getShop;
        }
    },
    emits:['selected'],
    methods:{
        selectedIndir: function(){
            let indir = this.listaIndirizzi.find(el => el.CodiceIndirizzo == this.k2Indir);
            this.$emit('selected',{indirizzo: indir, Spedizione: 0, K2_ANAGEN: this.k2Indir});
        },
        FormatIndir(indirizzo){
            let indir = "";
            if(indirizzo != null ){
                if(indirizzo.Descrizione != null){
                    indir = indirizzo.Descrizione;
                }
                if(indirizzo.Indirizzo){
                    indir =indir +" - " + indirizzo.Indirizzo;
                }
            }
            return indir;
        }
    },
    mounted: function(){
        AuthService.getIndirizziSpedizioneB2B( this.$store.getters.getToken ).then(data => {
            if (!data) { 
                this.$router.push("/"); 
            }
            else {                   
                this.listaIndirizzi = data;
                /*this.listaIndirizzi.push({
                    Cap: null,
                    Citta: null,
                    Provincia: null,
                    Descrizione: "Ritiro presso ns. magazzino",
                    Indirizzo: null,
                    CodiceIndirizzo: -1
                });*/
            }
        });
    }
}
</script>
<style>

</style>