<template>
    <section class="Shopperpage">
        <div class="Container">
            <div class="Box">
                <div class="Bookmark"><span>Carrello</span></div>
            </div>
        </div>
        <div class="Container">
            <div v-if="this.TestataCarrello && this.RigheCarrello.length > 0" class="Box"> 
                <div class="Binder">
                    <aside class="Aside">
                        <h2>Dati dell'ordine</h2>
                        <div class="ShoppingInfo">
                            <!-- Inizio repeater -->
                            <BillingAddressCartB2C
                                v-if="this.shop == 'b2c'"
                                @selected="this.setIndirB2C($event)"
                                @disableCheckout="this.disableCheckout($event)"
                            />
                            <BillingAddressCartB2B
                                v-if="this.shop == 'b2b'"
                                @selected="this.setIndirB2B($event)"
                            />
                            <!-- Fine repeater -->
                            <!--<div class="ShoppingAddNew" v-if="TipoSpedizione  > 0 && this.shop != 'b2b'">-->
                        </div>
                        <div class="ShoppingInfo">
                            <h3 class="ShoppingInfoIndex">Fatturazione</h3>
                            <div v-if="!isB2B">
                                <div class="ShoppingAddress">
                                    <div class="FormRow">
                                        <div class="FormBoxWide">
                                            <div class="FormRadioBox">
                                                <div class="RadioField">
                                                    <input type="radio" name="Fatturazione" v-model="TipoFatturazione" id="scontrino" value="1" checked/>
                                                    <span class="Radiomark">
                                                    </span></div>
                                                <div class="RadioLabel">Scontrino fiscale</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ShoppingAddress">
                                    <div class="FormRow">
                                        <div class="FormBoxWide">
                                            <div class="FormRadioBox">
                                                <div class="RadioField">
                                                    <input type="radio" name="Fatturazione" v-model="TipoFatturazione" id="fattura" value="2"/>
                                                    <span class="Radiomark">
                                                    </span></div>
                                                <div class="RadioLabel">Fattura</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <p>Condizioni di fatturazione: </p>
                                <p>{{ModoPagamento}}</p>
                            </div>
                            <div class="ShoppingAddNew" v-if="TipoFatturazione != 1">
                                <div class="Button">
                                    <a href="javascript:void(0);" @click="openModalBilling()">Inserisci dati fatturazione</a>
                                </div>
                            </div>
                        </div>
                        <div class="ShoppingInfo">
                            <h3 class="ShoppingInfoIndex">Note ordine</h3>
                            <div class="ShoppingAddress">
                                <div class="FormRow">
                                    <div class="FormBoxWide">
                                    <textarea v-model="NoteOrdine" rows="4" cols="50" maxlength="200"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="CheckoutBox">
                                <div class="CheckoutButton">
                                    <div class="Button Big CustomColor2">
                                        <form id="payform" method='POST' :action="PaymentData.requestUrl" enctype="application/x-www-form-urlencoded">
                                                <input v-for="( Valore, Nome ) in PaymentData.requestParams"
                                                    :key="Nome" 
                                                    type='hidden' 
                                                    :name="Nome"
                                                    :value="Valore"
                                                />
                                        </form>
                                        <a href="javascript:void(0);" @click="this.toPaymentB2C" v-if="!isB2B">Procedi al pagamento</a>
                                        <a href="javascript:void(0);" @click="this.toPaymentB2C" v-if="isB2B">Conferma l'ordine</a>
                                    </div>
                                </div>
                            </div>
                    </aside>
                    <div class="Content" v-if="this.TestataCarrello && this.RigheCarrello.length > 0">
                        <h2>Conferma l'ordine</h2>
                        <div class="CheckoutInfo">
                            <div v-for="riga in this.RigheRaggruppate" :key="riga.key" class="CheckoutTable">
                                <h3>{{riga.key}}</h3>
                                <table width="100%" border="0" cellspacing="0" cellpadding="0" summary="Tabella del carrello; in questa tabella sono riassunti i dati dell'ordine">
                                    <tbody>
                                        <tr class="TableHead">
                                            <th scope="col" class="CheckoutTableRemove">&nbsp;</th>
                                            <th scope="col" class="CheckoutTableItem">Articolo</th>
                                            <th scope="col" class="CheckoutTableQty">Quantità</th>
                                            <th scope="col" class="CheckoutTablePrice">Prezzo unit.</th>
                                            <th scope="col" class="CheckoutTableTotal">Totale</th>
                                        </tr>
                                        <CartSummaryRow 
                                            v-for="Riga in riga.values" 
                                            :key="Riga.CodiceArticoloVariante" 
                                            :Riga="Riga"
                                        />
                                    </tbody>
                                </table>
                            </div>
                            <div class="CheckoutTotalPriceBox" v-if="this.TestataCarrello.PrezzoTotale">
                                <span v-if="this.$store.getters.getShop == 'b2c'">Totale articoli (IVA incl.)</span>
                                <span v-else>Totale articoli (IVA escl.)</span>
                                <strong>{{ toEUR( this.TestataCarrello.PrezzoTotale ) }}</strong>
                            </div>
                            <div class="CheckoutTotalPriceBox">
                                <span>Spedizione</span>
                                <strong> {{ toEUR( this.costoSpedizione )}}</strong>
                            </div>
                            <div class="CheckoutTotalPriceBox">
                                <span v-if="this.$store.getters.getShop == 'b2c'">Totale carrello (IVA incl.)</span>
                                <span v-else>Totale carrello (IVA escl.)</span>
                                <strong>{{ toEUR( totCarrello ) }}</strong>
                            </div>
                            <div class="CheckoutBox">
                                <div class="CheckoutButton">
                                    <div class="Button Big CustomColor2">
                                        <form id="payform" method='POST' :action="PaymentData.requestUrl" enctype="application/x-www-form-urlencoded">
                                                <input v-for="( Valore, Nome ) in PaymentData.requestParams"
                                                    :key="Nome" 
                                                    type='hidden' 
                                                    :name="Nome"
                                                    :value="Valore"
                                                />
                                        </form>
                                        <a href="javascript:void(0);" @click="this.toPaymentB2C" v-if="!isB2B">Procedi al pagamento</a>
                                        <a href="javascript:void(0);" @click="this.toPaymentB2B" v-if="isB2B">Conferma l'ordine</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Content" v-else>
                <h1>Ooops ... il carrello sembra vuoto.</h1>
                <a href="#">Torna alla home</a>
            </div>
        </div>
    </section>

    <vue-final-modal v-model="showModalB" name="billing">
        <div class="Box" style="">
            <form>
                <fieldset>
                    <legend>Indirizzo di fatturazione</legend>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Codice Fiscale</label>
                            <input type="text" id="CodiceFiscale" v-model="this.Fatturazione_Codice_Fiscale" maxlength="20">
                        </div>
                        <div class="FormBox50">
                            <label>P.IVA</label>
                            <input type="text" id="P_Iva" v-model="this.Fatturazione_Partita_IVA" maxlength="12">
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Ragione Sociale</label>
                            <input type="text" id="RagioneSociale" v-model="this.Fatturazione_Ragione_Sociale" >
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Codice SDI</label>
                            <input type="text" id="CodiceSdi" v-model="this.Fatturazione_Codice_SDI" >
                        </div> 
                        <div class="FormBox50">
                            <label>PEC / Email</label>
                            <input type="text" id="PEC" v-model="this.Fatturazione_PEC" >
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Nome</label>
                            <input type="text" id="Fatturazione_Nome" v-model="Fatturazione_Nome" maxlength="20">
                        </div>
                        <div class="FormBox50">
                            <label>Cognome</label>
                            <input type="text" id="Fatturazione_Cognome" v-model="Fatturazione_Cognome" maxlength="40">
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBoxWide">
                            <label>Indirizzo (Via)</label>
                            <input type="text" id="Fatturazione_Indirizzo" v-model="Fatturazione_Indirizzo" maxlength="40">
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Città</label>
                            <input type="text" id="Fatturazione_Citta" v-model="Fatturazione_Citta" maxlength="40">
                        </div>
                        <div class="FormBox50">
                            <label>CAP</label>
                            <input type="text" id="Fatturazione_Cap" v-model="Fatturazione_CAP" maxlength="10">
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBox50">
                            <label>Provincia</label>
                            <select name="provincia" id="Fatturazione_Provincia" v-model="Fatturazione_Provincia">
                                <option v-for="provincia in this.listaProvince" :key="provincia.CodiceProvincia" :value="provincia.CodiceProvincia">{{provincia.Descrizione}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="WhiteSpace">&nbsp;</div>
                    <div class="FormRow">
                        <div class="FormBoxWide TwinButtons">
                            <div class="Button Small">
                                <input type="button" @click="showModalB = false" value="Chiudi">
                            </div>
                            <div class="Button Small">
                                <input type="button" @click="this.setDatiFatturazione" value="Salva">
                            </div>
                        </div>
                    </div>
                </fieldset>
                </form>
        </div>
    </vue-final-modal>
</template>

<script>
import {priceMixin, valutaMixin, risorseMixin, userMixin} from "@/mixin.js";
import CartSummaryRow from "@/components/CartSummaryRow.vue";
import BillingAddressCartB2B from "@/components/BillingAddressCartB2B.vue"
import BillingAddressCartB2C from "@/components/BillingAddressCartB2C.vue"

export default {
    name: "ShoppingCartNew",
    data(){
        return {
            K2AnagenIndir: 0,
            showModalB: false,
            showModalS: false,
            PaymentData:[],
            Testata: [],
            Righe: [],
            TotaleArticoli: 0,
            TotaleCarrello:0,
            TotaleCarrelloCent:0,

            Fatturazione_Nome:"",
            Fatturazione_Cognome:"",
            Fatturazione_Indirizzo:"",
            Fatturazione_Citta:"",
            Fatturazione_CAP:"",
            Fatturazione_Provincia:"",
            Fatturazione_Codice_Fiscale:"",
            Fatturazione_Partita_IVA:"",
            NoteOrdine: "",
            Fatturazione_Ragione_Sociale: "",
            Fatturazione_Codice_SDI: "",
            Fatturazione_PEC: "",
            TipoFatturazione: 1,
            TipoSpedizione: -2,
            costoSpedizione: 0,
            enableCheckout_N: false,
            enableCheckout_C: false,
            enableCheckout_I: false
        }        
    },      
    mixins: [priceMixin, valutaMixin, risorseMixin, userMixin],
    components: {
        CartSummaryRow,
        BillingAddressCartB2B,
        BillingAddressCartB2C
    },
	computed:{
        RigheCarrello: function(){
            let rc = this.$store.getters.getCarrello.Righe;
            if(rc){
                return rc;
            }
            else{
                return [];
            }
        }, 
        RigheRaggruppate: function(){
            let rc = this.$store.getters.getCarrello.Righe;  
            return this.groupByArray(rc,'DescrSocieta');
        },
        TestataCarrello: function(){
            let c = this.$store.getters.getCarrello.Testata;
            if(c){
                return c;
            }
            else{
                return {};
            }
        },     
		totCarrello: function(){
            let TotCart = this.TestataCarrello.PrezzoTotale + this.costoSpedizione;
            return TotCart;
		},
        shop: function(){
            return this.$store.getters.getShop;
        },
        listaProvince: function(){
            let p = this.$store.getters.getProvince;
            if(p){
                return p;
            }else{
                return [] ;
            }
        },
        enableCheckout: function(){
            return this.enableCheckout_N && this.enableCheckout_C && this.enableCheckout_I;
        }
    },

    methods: {
        groupByArray(xs, key) {
            return xs.reduce(
                function (rv, x) {
                    let v = key instanceof Function ? key(x) : x[key];
                    let el = rv.find((r) => r && r.key === v);
                    if (el) { 
                        el.values.push(x);
                    } 
                    else {
                        rv.push({ key: v, values: [x] });
                    }
                return rv; },
            []);
        },
        prepareOrder: function(){
            let totCent = (this.totCarrello * 100).toFixed(0);
            this.$store.commit('setK2AnagenIndir', this.K2AnagenIndir);
            this.$store.dispatch('setTotaleCarrello',  this.totCarrello );
            this.$store.dispatch('setTotaleCarrelloCent',  totCent );
            this.$store.dispatch('setTestataOrdine', this.Testata);
            this.$store.dispatch('setRigheOrdine', this.Righe);
            this.$store.dispatch('setSpedizione', this.TipoSpedizione);
            this.$store.dispatch('setFatturazione', this.TipoFatturazione);
            this.$store.dispatch('setNoteOrdine', this.NoteOrdine);
            if(this.TipoSpedizione > 0){
                this.$store.commit('setK2AnagenIndir', 0);
            }else{
                this.$store.commit('setCostoSpedizione', 0);
            }
            if (this.TipoFatturazione == 2){
                let rep = this.setDatiFatturazione();
                if(rep == -1){
                    this.$toast.error('Dati di fatturazione non completi, controllare i campi inseriti e riprovare');
                    return -1;
                }
            }
        },
        CheckDatiFatturazione(){
            let predCfPiva = !(!this.Fatturazione_Codice_Fiscale.length == 0 || !this.Fatturazione_Partita_IVA.length == 0);
            let predRagSoc = false;
            if((this.Fatturazione_Partita_IVA != null && this.Fatturazione_Partita_IVA.length > 0)){
                predRagSoc = !(this.Fatturazione_Ragione_Sociale != null && this.Fatturazione_Ragione_Sociale.length > 0);
            }
            let predSdiPec = !(!this.Fatturazione_Codice_SDI.length == 0 || !this.Fatturazione_PEC.length == 0);
            let predIndirizzo = !this.Fatturazione_Indirizzo.length > 0;
            let predCitta = !this.Fatturazione_Citta.length > 0;
            let predCap = !this.Fatturazione_CAP.length > 0;
            let predProvincia = !this.Fatturazione_Provincia.length > 0;

            let v = predCfPiva || predRagSoc || predSdiPec || predIndirizzo || predCitta || predCap || predProvincia;

            if(predIndirizzo){
                this.SetInputError("Fatturazione_Indirizzo");
            }
            if(predCitta){
                this.SetInputError("Fatturazione_Citta");
            }
            if(predCap){
                this.SetInputError("Fatturazione_Cap");
            }
            if(predProvincia){
                this.SetInputError("Fatturazione_Provincia");
            }
            if(this.Fatturazione_Codice_SDI.length == 0 && this.Fatturazione_PEC.length == 0){
                this.SetInputError("CodiceSdi");
                this.SetInputError("PEC");
            }
            if(this.Fatturazione_Codice_Fiscale.length == 0 && this.Fatturazione_Partita_IVA.length == 0){
                this.SetInputError("CodiceFiscale");
                this.SetInputError("P_Iva");
            }
            if((this.Fatturazione_Partita_IVA != null && this.Fatturazione_Partita_IVA.length > 0)){
                if(predRagSoc){
                    this.SetInputError("RagioneSociale");
                }
            }

            return v;
        },
        setDatiFatturazione(){
            if (this.CheckDatiFatturazione()) {
                this.$toast.error('Dati di fatturazione non completi, controllare i campi inseriti e riprovare');
                return -1;
            }
            let Fatturazione = [];
            Fatturazione["FATT_INDIR"] = this.Fatturazione_Indirizzo;
            Fatturazione["FATT_CIVICO"] = this.Fatturazione_Indirizzo;
            Fatturazione["FATT_CAP"] = this.Fatturazione_CAP;
            Fatturazione["FATT_CITTA"] = this.Fatturazione_Citta;
            Fatturazione["FATT_CODNAZI"] = "IT";
            Fatturazione["FATT_CODPROV"] = this.Fatturazione_Provincia;
            Fatturazione["FATT_CODFIS"] = this.Fatturazione_Codice_Fiscale;
            Fatturazione["FATT_PIVA"] = this.Fatturazione_Partita_IVA;         
            Fatturazione["FATT_RAGSOC"] = this.Fatturazione_Ragione_Sociale;
            Fatturazione["FATT_CODSDI"] = this.Fatturazione_Codice_SDI;
            Fatturazione["FATT_PEC"] = this.Fatturazione_PEC;
            Fatturazione["FATT_NOME"] = this.Fatturazione_Nome;
            Fatturazione["FATT_COGNOME"] = this.Fatturazione_Cognome;
            this.$store.dispatch('setDatiFatturazione', JSON.stringify(Object.assign({}, Fatturazione)) );
            this.closeModalBilling();
            return 0;
        },
        toPaymentB2C: function(){
            let rep = this.prepareOrder();
            if(rep == -1){
                return;
            }else{
                if(this.enableCheckout == true || this.TipoSpedizione == -2){
                    this.$router.push({ name: 'Payment'});
                }else{
                    this.$toast.error('Impossibile procedere al pagamento, controllare i dati di spedizione inseriti');
                }
            }
        },
        toPaymentB2B: function(){
            let rep = this.prepareOrder();
            if(rep == -1){
                return;
            }else{
                this.$router.push({ name: 'OrdineSquadra'});
            }
        },
        openModalBilling() {
            this.$vfm.show('billing');
        },
        closeModalBilling(){
            this.$vfm.hide('billing');
        },
        setIndirB2C:function(ev){
            this.costoSpedizione = ev.Spedizione;
            this.$store.commit('setCostoSpedizione', ev.Spedizione);
            if(ev.indirizzo != 1){
                this.TipoSpedizione = 1;
            }else{
                this.TipoSpedizione = -2
            }
        },
        setIndirB2B:function(ev){
            this.K2AnagenIndir = ev.K2_ANAGEN;
            this.costoSpedizione = ev.Spedizione;
        },
        disableCheckout(ev){
            switch(ev.Campo) {
                case 'Nome':
                    this.enableCheckout_N = ev.Value;
                    break;

                case 'Cognome':
                    this.enableCheckout_C = ev.Value;
                    break;

                case 'Indirizzo':
                    this.enableCheckout_I = ev.Value;
                    break;
            }
        },
        ClearInputError: function(val, id){
            document.getElementById(id).classList.remove('errorBorder');
		},
        SetInputError: function(id){
            document.getElementById(id).classList.add('errorBorder');
        }
    },
    mounted: function(){    
        this.$store.dispatch({
            type: 'setLoader',
            value: true
        });
        this.$store.dispatch({
            type: 'getCarrello',
            token: this.$store.getters.getToken,
            shop: this.$store.getters.getShop
        }).then((data) =>{
            this.$store.dispatch({
                type: 'setLoader',
                value: false
            });
            if(this.$store.getters.getCarrello) {
                this.Testata = this.$store.getters.getCarrello.Testata;
            }
            else{
                this.Testata = {};
            }
        });
    },
    watch: {
        Fatturazione_Codice_Fiscale: function(val){
            this.ClearInputError(val, 'CodiceFiscale');	
            this.ClearInputError(val,'P_Iva');		
		},
        Fatturazione_Partita_IVA: function(val){
            this.ClearInputError(val,'P_Iva');
            this.ClearInputError(val, 'CodiceFiscale');	
        },
        Fatturazione_Ragione_Sociale: function(val){
            this.ClearInputError(val,'RagioneSociale');
        },
        Fatturazione_Codice_SDI: function(val){
            this.ClearInputError(val,'CodiceSdi');
        },
        Fatturazione_PEC: function(val){
            this.ClearInputError(val,'PEC');
        },
        Fatturazione_Nome: function(val){
            this.ClearInputError(val,'Fatturazione_Nome');
        },
        Fatturazione_Cognome: function(val){
            this.ClearInputError(val,'Fatturazione_Cognome');
        },
        Fatturazione_Indirizzo: function(val){
            this.ClearInputError(val,'Fatturazione_Indirizzo');
        },
        Fatturazione_Citta: function(val){
            this.ClearInputError(val, 'Fatturazione_Citta');
        },
        Fatturazione_CAP: function(val){
            this.ClearInputError(val,'Fatturazione_Cap');
        },
        Fatturazione_Provincia: function(val){
            this.ClearInputError(val,'Fatturazione_Provincia');
        }
        
    }
}
</script>
<style>
.myStyle{
    background-color: gray;
}
.myStyle:hover {
    background-color: lightgrey;
}
.errorBorder{
	border: 2px solid #ff0000 !important;
}
</style>