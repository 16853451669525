<template>
    <img :src="this.productimage" />
</template>

<script>
const baseUrl = process.env.VUE_APP_BASEMEDIA_URL;
export default {
    props: [
        'PathArticolo'
    ],
    computed: {
        productimage() {
            let v = baseUrl + this.PathArticolo;
            return v;
        }
    }
}
</script>